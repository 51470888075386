<template>
  <div class="tw-bg-gray-100 tw-px-8 tw-py-4 tw-leading-10" v-html="htmlContents" />
</template>

<script>
export default {
  name: 'NoticeBox',
  props: {
    htmlContents: String,
  },
};
</script>
