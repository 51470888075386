<template id="color-input-template">
  <div class="form-group" :class="{ 'has-error': error }">
    <label :for="$.uid.toString()" class="control-label">{{ label }}</label>
    <p v-if="hint" data-test-id="custom-color-hint">
      <small class="text-muted--dark">{{ hint }}</small>
    </p>
    <input v-model="fieldValue" aria-hidden type="hidden" :name="fieldName" />
    <div class="custom-color">
      <div
        role="button"
        :aria-label="label"
        tabindex="0"
        class="custom-color__preview"
        :data-test-id="`custom-color-preview-${getFieldName}`"
        :style="{ backgroundColor: fieldValue || defaultColor }"
        @click.prevent="selecting = !selecting"
        @keydown.enter="selecting = !selecting"
      ></div>
      <div
        v-if="!selecting"
        class="custom-color__string-input"
        :data-test-id="`custom-color-${getFieldName}`"
        :class="{ 'has-error': hexStringError }"
      >
        <div class="input-group">
          <span class="input-group-addon">#</span>
          <input
            :id="$.uid.toString()"
            v-model="stringInput"
            class="form-control"
            type="text"
            name="string_input"
            maxlength="8"
          />
        </div>
      </div>
      <a
        v-if="selecting"
        href="#"
        class="btn btn-default btn--round btn--size-xs"
        @click.prevent="selecting = false"
      >
        {{ t('vue_templates.color_input.close_picker') }}
      </a>
      <a
        v-if="showRestoreDefault"
        href="#"
        class="btn btn-default btn--round btn--size-xs m-l-2"
        data-test-id="custom-color-restore-default"
        @click.prevent="restoreDefault"
      >
        {{ t('vue_templates.color_input.restore_default') }}
      </a>
    </div>
    <chrome-picker
      v-if="selecting"
      ref="picker"
      v-model="selectedColor"
      :class="{
        'color-picker__chrome-picker--fixed': fixed,
      }"
      :disable-alpha="true"
      data-test-id="custom-color-picker"
      @input="pickerUpdated"
    />
    <span v-if="error" class="help-block">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import i18n from '@/mixins/i18n';
import { Chrome } from 'vue-color';
import _ from 'lodash';

export default defineComponent({
  name: 'ColorInput',

  components: {
    'chrome-picker': Chrome,
  },

  mixins: [i18n],
  props: {
    fieldName: String,
    initColor: String,
    defaultColor: {
      type: String,
      default: '#ffffff',
    },
    error: String,
    label: String,
    hint: String,
    fixed: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update'],
  data: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias, @typescript-eslint/no-explicit-any
    const self: any = this;
    return {
      fieldValue: this.initColor || this.defaultColor,
      hexStringError: false,
      selecting: false,
      selectedColor: {
        hex: this.initColor || this.defaultColor,
      },
      stringInput: self.stripHash(this.initColor || this.defaultColor),
      hexRegex: /^(?:[0-9a-fA-F]{3}){1,2}$/,
    };
  },
  computed: {
    showRestoreDefault: function () {
      if (!this.defaultColor) {
        return false;
      }
      return this.fieldValue && this.fieldValue.toLowerCase() !== this.defaultColor.toLowerCase();
    },
    getFieldName() {
      return _.kebabCase(this.label);
    },
  },
  watch: {
    fieldValue(value) {
      this.$emit('update', value);
    },
    stringInput: function (newValue) {
      if (!this.hexRegex.test(newValue)) {
        this.hexStringError = true;
        return;
      }
      let newValueWithHash = '#' + newValue.toLowerCase();
      this.hexStringError = false;
      this.fieldValue = newValueWithHash;
      this.selectedColor.hex = newValueWithHash;
    },
  },
  methods: {
    confirmSelection: function () {
      this.fieldValue = this.selectedColor.hex;
      this.stringInput = this.stripHash(this.selectedColor.hex);
      this.selecting = false;
    },
    restoreDefault: function () {
      this.fieldValue = this.defaultColor;
      this.selectedColor.hex = this.defaultColor;
      this.stringInput = this.stripHash(this.defaultColor);
      this.selecting = false;
    },
    stripHash: function (str) {
      return str.replace(/#/g, '').toLowerCase();
    },
    pickerUpdated: function (newValue) {
      this.fieldValue = newValue.hex;
      this.stringInput = this.stripHash(newValue.hex);
    },
  },
});

export interface IColourInputProps {
  fieldName: string;
  initColor: string;
  defaultColor: string;
  error: string;
  label: string;
  hint?: string;
}
</script>

<style scoped lang="scss">
.color-picker__chrome-picker--fixed {
  position: absolute;
  z-index: 200;
}
</style>
