<template>
  <div class="users-list">
    <div v-show="error" class="text-muted text-center m-a-2">{{ error }}</div>

    <!-- Edit columns modal -->
    <Modal
      id="editColumns"
      :key="`editColumns-${editColumnsIndex}`"
      :header="t('vue_templates.users_list.edit_columns')"
      :back-text="t('helpers.buttons.back')"
      :primary-button-text="t('vue_templates.users_list.save_columns')"
      :primary-button-action="handleSaveColumns"
      :footer-border="true"
      :on-close="onEditColumnsClose"
    >
      <NoticeBox :html-contents="t('vue_templates.sortable_columns.update_warning')" />
      <DraggableList :list="editColumnList" />
      <template #footerContent>
        <AddFieldSelect
          data-test-id="users-list-add-columns-select"
          :selected-item-list="editColumnList"
          :add-item-list="availableColumnList"
          :add-button-text="t('helpers.buttons.add')"
          :start-adding-text="t('vue_templates.users_list.add_column')"
          :add-field-placeholder-text="t('vue_templates.users_list.select_field_to_add')"
          @on-field-selected="addSelectedColumn"
        />
      </template>
    </Modal>

    <!-- Top bar containing Actions/Filters/Batch Actions -->
    <div class="btn-toolbar users-list_actions-and-filters">
      <!-- Action list -->
      <DropdownList
        v-if="showActions"
        :selection-handler="handleActionMenu"
        :label="t('helpers.buttons.actions')"
        icon="t-settings-tools-configuration-preferences"
        :items="getActionMenuItems()"
        alignment="left"
      />

      <!-- Filter list -->
      <DropdownList
        :selection-handler="addFilter"
        :label="t('vue_templates.activity_feed.filter_by')"
        icon="t-filter"
        data-test-id="filters-button"
        :scrollable="false"
        :items="getFilterMenuItems()"
        alignment="left"
      />

      <user-batch-actions
        v-if="batchActionsAvailable"
        data-test-id="batch-actions"
        :user-ids="userIds"
        :roles="roles"
        :endpoint="batchActionsEndpoint"
        :languages="languages"
        :number-of-users="selectedCount"
        :all-pages="allSelectedAllPages"
        :params="params"
        @no-actions-available="hideBatchActions"
        @batch-action-message="handleBatchActionMessage"
        @batch-action-processing="handleBatchActionProcessing"
        @batch-action-error="handleBatchActionError"
      />
      <div class="users-list-filter--align-left">
        <slot name="filterRowRightButton" />
      </div>
    </div>

    <!-- Search below button list -->
    <SearchInput
      v-if="showSearch"
      v-model="search"
      :init-search="search"
      :placeholder="t('vue_templates.users_list.search_placeholder')"
      class="users-list__search-input"
      type="discover-content"
      data-test-id="search-name-or-email"
    />

    <csv-exporter
      v-model:init-csv-export-processing="csvExportProcessing"
      :csv-endpoint="csvEndpoint"
      :init-csv-export-url="initCsvExportUrl"
      :current-params="params"
    />

    <!-- Optional checkboxes that pull left -->
    <div v-if="showingTeam" class="checkbox">
      <input id="nested_users" v-model="nestedUsers" type="checkbox" />
      <label for="nested_users">
        {{ t('vue_templates.users_list.all_sub_team_members') }}
        <ToolTip icon="info" :title="t('vue_templates.users_list.sub_team_memebers_tooltip')" />
      </label>
    </div>

    <div v-if="showingManaged" class="checkbox">
      <!-- TODO - Not ideal to have two elements with the same ID just in case they do render together -->
      <input id="nested_users" v-model="nestedUsers" type="checkbox" />
      <label for="nested_users">
        {{ t('vue_templates.users_list.all_submanaged') }}
      </label>
    </div>

    <FilterContainer v-if="showFilters" :on-clear-all="clearFilters">
      <!-- user name filter -->
      <TextFilter
        v-if="appliedFilters.indexOf('Name') > -1"
        v-model="name"
        :label="t('activerecord.attributes.user.full_name')"
        :placeholder="t('vue_templates.users_list.search_name')"
        @filterRemoved="removeFilter('Name')"
      />
      <!-- login name filter TODO - is this used? needs placeholder -->
      <TextFilter
        v-if="appliedFilters.indexOf('LoginName') > -1"
        v-model="loginName"
        :label="loginNameFilterLabel"
        placeholder=""
        @filterRemoved="removeFilter('LoginName')"
      />
      <!-- email filter -->
      <TextFilter
        v-if="appliedFilters.indexOf('Email') > -1"
        v-model="email"
        :label="t('activerecord.attributes.user.email')"
        :placeholder="t('vue_templates.users_list.search_email')"
        @filterRemoved="removeFilter('Email')"
      />
      <!-- role filter -->
      <SelectFilter
        v-if="appliedFilters.indexOf('Role') > -1"
        v-model="selectedRole"
        :label="t('activerecord.attributes.user.role')"
        :items="roles"
        :placeholder="t('vue_templates.users_list.select_role')"
        @filterRemoved="removeFilter('Role')"
      />
      <!-- No team filter -->
      <BooleanFilter
        v-if="appliedFilters.indexOf('NoTeam') > -1"
        :label="t('vue_templates.users_list.no_team')"
        :value="t('vue_templates.users_list.only_no_team')"
        @filterRemoved="removeFilter('NoTeam')"
      />
      <!-- Team filter -->
      <SelectizeFilter
        v-if="appliedFilters.indexOf('Teams') > -1"
        :label="t('activerecord.models.team.other')"
        :placeholder="t('vue_templates.users_list.select_team')"
        :sr-label="t('vue_templates.users_list.select_team')"
        selector="teams"
        :url="teamsEndpoint"
        value-field="id"
        label-field="name"
        :max-items="20"
        :preload="true"
        :close-after-select="true"
        :on-load="onLoadTeams"
        :on-change="onChangeTeams"
        :ajax-data="ajaxDataTeams"
        @filterRemoved="removeFilter('Teams')"
      />
      <!-- Labels filter -->
      <SelectizeFilter
        v-if="appliedFilters.indexOf('Labels') > -1"
        :label="t('activerecord.models.label.other')"
        :placeholder="t('vue_templates.users_list.select_label')"
        :sr-label="t('vue_templates.users_list.select_label')"
        selector="labels"
        :url="labelsEndpoint"
        value-field="id"
        label-field="name"
        :max-items="20"
        :preload="true"
        :close-after-select="true"
        :on-change="onChangeLabels"
        :ajax-data="ajaxDataLabels"
        @filterRemoved="removeFilter('Labels')"
      />
      <!-- Manager filter -->
      <BooleanFilter
        v-if="appliedFilters.indexOf('Managers') > -1"
        :label="t('vue_templates.users_list.managers')"
        :value="t('vue_templates.users_list.only_managers')"
        @filterRemoved="removeFilter('Managers')"
      />
      <!-- Coaches filter -->
      <BooleanFilter
        v-if="appliedFilters.indexOf('Coaches') > -1"
        :label="t('vue_templates.users_list.coaches')"
        :value="t('vue_templates.users_list.only_coaches')"
        @filterRemoved="removeFilter('Coaches')"
      />
      <!-- Coached by filter -->
      <SelectizeFilter
        v-if="appliedFilters.indexOf('Coached by') > -1"
        selectize-type="UsersDropdown"
        :label="t('vue_templates.users_list.coached_by')"
        :placeholder="t('vue_templates.users_list.select_coached_by')"
        :sr-label="t('vue_templates.users_list.select_coached_by')"
        selector="coachedBy"
        :open-on-focus="true"
        :preload="true"
        :max-items="20"
        :close-after-select="true"
        :on-change="onChangeCoachedBy"
        :load="loadCoachedBy"
        @filterRemoved="removeFilter('Coached by')"
      />
      <!-- Location filter -->
      <SelectizeFilter
        v-if="appliedFilters.indexOf('Location') > -1"
        :label="t('activerecord.models.location.other')"
        :placeholder="t('vue_templates.users_list.select_locations')"
        :sr-label="t('vue_templates.users_list.select_locations')"
        :url="locationsEndpoint"
        selector="locations"
        value-field="id"
        label-field="name"
        :max-items="20"
        :open-on-focus="true"
        :preload="true"
        :close-after-select="true"
        :on-change="onChangeLocations"
        @filterRemoved="removeFilter('Location')"
      />
      <!-- Primary language filter -->
      <SelectFilter
        v-if="appliedFilters.indexOf('Primary Language') > -1"
        v-model="selectedLanguage"
        :label="t('activerecord.attributes.user.language')"
        :items="languageFilters"
        :placeholder="t('vue_templates.users_list.select_language')"
        @filterRemoved="removeFilter('Primary Language')"
      />
      <!-- Secondary language filter -->
      <SelectFilter
        v-if="appliedFilters.indexOf('Secondary Language') > -1"
        v-model="selectedSecondaryLanguage"
        :label="t('vue_templates.users_list.secondary_language')"
        :items="languageFilters"
        :placeholder="t('vue_templates.users_list.select_language')"
        @filterRemoved="removeFilter('Secondary Language')"
      />
      <!-- Expertise filter -->
      <SelectizeFilter
        v-if="appliedFilters.indexOf('Expertise') > -1"
        :label="t('activerecord.attributes.user.specialism')"
        :placeholder="t('vue_templates.users_list.select_expertise')"
        :sr-label="t('vue_templates.users_list.select_expertise')"
        selector="expertises"
        value-field="id"
        label-field="name"
        :max-items="20"
        :preload="true"
        :close-after-select="true"
        :on-initialize="onInitializeExpertise"
        :on-load="onLoadExpertise"
        :on-change="onChangeExpertise"
        :ajax-data="ajaxDataExpertise"
        @filterRemoved="removeFilter('Expertise')"
      />
      <!-- Signed up at filter -->
      <TableFilterWrapper
        v-if="appliedFilters.indexOf('Signed up via form at') > -1"
        :label="t('vue_templates.users_list.signed_up_via_form')"
        label-type="legend"
        :emit-to-parent="false"
        @filterRemoved="removeFilter('Signed up via form at')"
      >
        <DateRangeFilter
          v-model="signedUp"
          data-test-id="users-list-signed-up-at-filter"
          class="m-y-1"
        />
      </TableFilterWrapper>
      <!-- Invitation date filter -->
      <TableFilterWrapper
        v-if="appliedFilters.indexOf('Invitation date') > -1"
        :label="t('activerecord.attributes.user.invitation_sent_at')"
        :emit-to-parent="false"
        label-type="legend"
        @filterRemoved="removeFilter('Invitation date')"
      >
        <DateRangeFilter
          v-model="invitation"
          data-test-id="users-list-invitation-date-filter"
          class="m-y-1"
        />
      </TableFilterWrapper>
    </FilterContainer>

    <!-- Result pagination count -->
    <ShowingCount
      v-show="paginationData"
      :showing-count="users.length"
      :total-count="paginationData ? paginationData.total_count : 0"
    />

    <!-- TODO these checkboxes can be swapped for the component version -->
    <div
      v-show="appliedFilters.indexOf('Status') > -1"
      class="users-list__status-filters form-group"
    >
      <span class="control-label m-r-2">{{ t('users.show.status').toUpperCase() }}:</span>
      <label class="radio-inline p-l-0">
        <input
          v-model="status"
          type="checkbox"
          value="not_invited"
          data-test-id="not-yet-invited-users"
        />
        <span>{{ t('users.user.not_invited') }}</span>
      </label>
      <label class="radio-inline p-l-0">
        <input
          v-model="status"
          type="checkbox"
          value="invite_scheduled"
          data-test-id="invite-scheduled-users"
        />
        <span>{{ t('users.user.invite_scheduled') }}</span>
      </label>
      <label class="radio-inline p-l-0">
        <input
          v-model="status"
          type="checkbox"
          value="invite_pending"
          data-test-id="invite-pending-users"
        />
        <span>{{ t('users.user.invite_pending') }}</span>
      </label>
      <label class="radio-inline p-l-0">
        <input v-model="status" type="checkbox" value="confirmed" data-test-id="confirmed-users" />
        <span>{{ t('users.user.confirmed') }}</span>
      </label>
      <label class="radio-inline p-l-0">
        <input
          v-model="status"
          type="checkbox"
          value="deactivated"
          data-test-id="deactivated-users"
        />
        <span> {{ t('users.user.deactivated') }}</span>
      </label>
      <a
        class="link-black m-l-2"
        href="#"
        @click.prevent="
          status = ['not_invited', 'invite_scheduled', 'invite_pending', 'confirmed', 'deactivated']
        "
      >
        <span>{{ t('vue_templates.search_filter.select_all') }}</span>
      </a>
      <a class="link-black m-l-1" href="#" @click.prevent="status = []">
        <span data-test-id="deselect-all-users">{{
          t('vue_templates.search_filter.deselect_all')
        }}</span>
      </a>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="batchActionProcessing" key="processing" class="alert alert-info p-a-1">
        {{ t('vue_templates.users_list.batch_processing') }}
      </div>
      <div v-else-if="batchActionMessage" key="message" class="alert alert-success p-a-1">
        {{ batchActionMessage }}
      </div>
      <div v-else-if="batchActionError" key="error" class="alert alert-danger p-a-1">
        {{ batchActionError }}
      </div>
    </transition>

    <div class="table-pretty__actions-wrapper m-y-1">
      <div class="table-pretty__wrapper" tabindex="0">
        <table class="table table-pretty">
          <thead>
            <tr>
              <!-- Selected checkboxes, will not move -->
              <td class="col--menu">
                <input
                  v-if="batchActionsAvailable"
                  type="checkbox"
                  data-test-id="select-all"
                  :aria-label="t('vue_templates.users_list.select_all_users_label')"
                  :checked="allSelected"
                  @click="toggleAllSelected"
                />
              </td>

              <template v-for="(column, index) in columnList">
                <!-- Username, sortable -->
                <TableHeader
                  v-if="column.referenced_attribute === 'full_name_and_job_title'"
                  :key="`header-${index}`"
                  :heading="column.name"
                  :sticky="index === 0"
                  :sortable="true"
                  :current-sort="sortKey"
                  :sort-key="column.sort_key"
                  :sort-order="sortOrder"
                  :default-order="1"
                  :click-handler="sortBy"
                  :callback="reloadData"
                />

                <!-- Team, has sub-heading -->
                <TableHeader
                  v-else-if="column.referenced_attribute === 'primary_team_and_manager'"
                  :key="`header-${index}-manager`"
                  :sticky="index === 0"
                  :heading="column.name"
                  :sub-heading="`(${getManagerTranslation()})`"
                />

                <!-- User role, sortable -->
                <TableHeader
                  v-else-if="column.referenced_attribute === 'role'"
                  :key="`header-${index}-role`"
                  :sticky="index === 0"
                  :heading="column.name"
                  :sortable="true"
                  :current-sort="sortKey"
                  :sort-key="column.sort_key"
                  :sort-order="sortOrder"
                  :default-order="-1"
                  :click-handler="sortBy"
                  :callback="reloadData"
                />

                <!-- Language, sortable -->
                <TableHeader
                  v-else-if="column.referenced_attribute === 'language'"
                  :key="`header-${index}-language`"
                  :sticky="index === 0"
                  :heading="column.name"
                  :sortable="true"
                  :current-sort="sortKey"
                  sort-key="language"
                  :sort-order="sortOrder"
                  :default-order="1"
                  :click-handler="sortBy"
                  :callback="reloadData"
                />

                <TableHeader
                  v-else-if="column.referenced_attribute === 'status'"
                  :key="`header-${index}-status`"
                  :centered="true"
                  :sticky="index === 0"
                  :heading="column.name"
                />

                <!-- Everything else -->
                <TableHeader
                  v-else
                  :key="`header-${index}-other`"
                  :sticky="index === 0"
                  :heading="column.name"
                />
              </template>
            </tr>
          </thead>

          <tbody
            is="vue:skeleton-table"
            v-if="updating"
            :column-count="columnsCount"
            :cols="skeletonTableData"
          />

          <tbody v-if="!updating">
            <TableEmpty
              v-if="users.length === 0 && !advancedGroupingRefreshing && !escalationLog"
              :columns="columnsCount"
              :image-path="noUsersImagePath"
              :empty-text="t('vue_templates.users_list.not_found')"
              :can-add="canManage && inviteSinglePath && inviteSinglePath.length > 0"
              :can-add-url="inviteSinglePath"
              :can-add-text="
                t('helpers.common.click_below_add', {
                  model: t('activerecord.models.user.one').toLowerCase(),
                })
              "
              :can-add-button-text="t('users.invite_user.invite_people')"
            />

            <tr v-else-if="users.length === 0" class="table--empty-row">
              <td class="text-muted text-center" :colspan="columnsCount">
                <div v-if="advancedGroupingRefreshing" class="m-y-3 table--empty-wrapper">
                  {{ t('vue_templates.users_list.still_refreshing') }}
                </div>
                <div v-else class="m-y-3 table--empty-wrapper">
                  <img :src="noUsersImagePath" alt="" />
                  <p v-if="escalationLog" class="m-t-1">
                    <strong>{{ t('vue_templates.escalations.users_list.not_found') }}</strong>
                    <br />
                    {{ t('vue_templates.escalations.users_list.will_display') }}
                  </p>
                </div>
              </td>
            </tr>

            <!-- Multiple page select all -->
            <tr v-if="showMultipageSelection">
              <td v-if="allSelectedAllPages" :colspan="columnsCount">
                All {{ selectedCount }} people are selected.
                <a href="#" @click.prevent="allSelectedAllPages = false">Clear selection?</a>
              </td>
              <td v-else :colspan="columnsCount">
                {{ selectedCount }} people on this page are selected.
                <a href="#" @click.prevent="allSelectedAllPages = true">
                  Select all {{ paginationData.total_count }} people?</a
                >
              </td>
            </tr>

            <!-- Main table content -->
            <tr v-for="user in users" :key="user.id">
              <!-- Checkbox and menu pips -->
              <td class="col--menu">
                <div class="centered">
                  <span v-if="batchActionsAvailable" class="label--padded">
                    <input
                      v-model="userIds"
                      :aria-label="t('vue_templates.users_list.select_user_label')"
                      type="checkbox"
                      :data-test-id="testId(user)"
                      :value="user.id"
                      @click="allSelected = allSelectedAllPages = false"
                    />
                  </span>
                  <div class="m-l-1 user-list__user-dropdown">
                    <user-dropdown
                      :id="user.id"
                      data-test-id="user-dropdown"
                      :in-table="true"
                      :actions-endpoint="user.actions.available_actions_user_path"
                      @refresh-user-teams="handleRefreshUserTeams($event, user)"
                      @remove-user="handleRemoveUser(user)"
                    />
                  </div>
                </div>
              </td>

              <!-- Orderable columns -->
              <template v-for="(column, index) in columnList">
                <!-- Name + avatar -->
                <td
                  v-if="column.referenced_attribute === 'full_name_and_job_title'"
                  :key="`data-${index}`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  <AvatarFullDescription
                    v-if="user?.full_name_and_job_title"
                    :avatar-url="user.full_name_and_job_title.avatar"
                    :show-path="user.full_name_and_job_title.show_path"
                    :full-name="user.full_name_and_job_title.full_name"
                    :on-break="user.status && user.status.on_break"
                    :job-title="user.full_name_and_job_title.job_title"
                  />
                </td>

                <!-- Team -->
                <td
                  v-else-if="column.referenced_attribute === 'primary_team_and_manager'"
                  :key="`data-${index}-manager`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  <template v-if="user.primary_team_and_manager">
                    <a
                      v-if="user.primary_team_and_manager.primary_team"
                      class="link--primary"
                      :href="user.primary_team_and_manager.primary_team.show_path"
                    >
                      {{ user.primary_team_and_manager.primary_team.name }}
                    </a>
                    <br />
                    <small>
                      <a
                        v-if="
                          user.primary_team_and_manager.manager &&
                          user.primary_team_and_manager.manager_show_path
                        "
                        class="text-muted"
                        :href="user.primary_team_and_manager.manager_show_path"
                      >
                        {{ user.primary_team_and_manager.manager }}
                      </a>
                      <span v-else-if="user.primary_team_and_manager.manager" class="text-muted">
                        {{ user.primary_team_and_manager.manager }}
                      </span>
                    </small>
                  </template>
                </td>

                <!-- Location -->
                <td
                  v-else-if="column.referenced_attribute === 'location_and_time_zone'"
                  :key="`data-${index}-location`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  {{ user.location_and_time_zone.locations }}
                  <small class="text-muted">{{ user.location_and_time_zone.time_zone }}</small>
                </td>

                <!-- User role -->
                <td
                  v-else-if="column.referenced_attribute === 'role'"
                  :key="`data-${index}-role`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  <i class="ic ic--larger" :class="user.role.role_icon" />
                  <span data-test-id="system-role">{{ user.role.role_label }}</span>
                  <small v-if="user.current_company" class="text-muted">
                    {{ t('en.vue_templates.users_list.switched_to') }}
                    <b>{{ user.current_company }}</b>
                  </small>
                </td>

                <!-- Language -->
                <td
                  v-else-if="column.referenced_attribute === 'language'"
                  :key="`data-${index}-language`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  {{ user.language }}
                </td>

                <!-- Status -->
                <td
                  v-else-if="column.referenced_attribute === 'status'"
                  :key="`data-${index}-status`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  <div class="label label--status" :class="user.status.status_data.class">
                    {{ user.status.status_data.status }}
                  </div>
                  <small class="text-muted text-center">
                    {{ user.status.status_data.time }}
                  </small>
                </td>

                <!-- Secondary teams -->
                <td
                  v-else-if="column.referenced_attribute === 'secondary_teams'"
                  :key="`data-${index}-secondary-team`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  <span
                    v-for="team in user.secondary_teams"
                    :key="team.id"
                    class="users-list__team"
                  >
                    <a class="link--primary" :href="team.show_path">{{ team.name }}</a>
                  </span>
                </td>

                <!-- Labels -->
                <td
                  v-else-if="column.referenced_attribute === 'labels'"
                  :key="`data-${index}-labels`"
                  :class="{ 'col--sticky': index === 0 }"
                >
                  <span v-for="label in user.labels" :key="label.id" class="users-list__team">{{
                    label.name
                  }}</span>
                </td>

                <!-- Anything else - custom fields included -->
                <td v-else :key="`data-${index}-other`" :class="{ 'col--sticky': index === 0 }">
                  {{ user[column.referenced_attribute] }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination
      :pagination-data="paginationData"
      :updating="paginationUpdating"
      @page-changed="handlePageChanged"
    />
  </div>
</template>

<script>
import tableHelpers from '@/mixins/tableHelpers';
import tooltipToggler from '@/mixins/tooltipToggler';
import csvExporter from '@/mixins/csvExporter';
import i18n from '@/mixins/i18n';
import TableEmpty from '@/components/atoms/Table/Empty.vue';
import TableHeader from '@/components/atoms/Table/Header.vue';
import Modal from '@/components/atoms/Modal/Index.vue';
import DraggableList from '@/components/molecules/DraggableList.vue';
import DropdownList from '@/components/atoms/DropdownList/Index.vue';
import SearchInput from '@/components/atoms/SearchInput/Index.vue';
import ShowingCount from '@/components/molecules/Table/ShowingCount.vue';
import ToolTip from '@/components/atoms/ToolTip.vue';
import {
  TableFilterWrapper,
  BooleanFilter,
  DateRangeFilter,
  TextFilter,
  FilterContainer,
  SelectFilter,
  SelectizeFilter,
} from '@/components/atoms/Filter';
import AddFieldSelect from '@/components/molecules/AddFieldSelect.vue';
import AvatarFullDescription from '@/components/molecules/Avatar/FullDescription.vue';
import NoticeBox from '@/components/atoms/NoticeBox/Index.vue';
import useUsersListProps from '@/composables/useUsersListProps';

export default {
  name: 'UsersList',
  compatConfig: { MODE: 3 },
  components: {
    AvatarFullDescription,
    BooleanFilter,
    DateRangeFilter,
    DraggableList,
    DropdownList,
    Modal,
    NoticeBox,
    SelectFilter,
    SearchInput,
    ShowingCount,
    TableEmpty,
    TableFilterWrapper,
    TableHeader,
    ToolTip,
    FilterContainer,
    TextFilter,
    SelectizeFilter,
    AddFieldSelect,
  },
  mixins: [i18n, tableHelpers, tooltipToggler, csvExporter],

  props: {
    ...useUsersListProps(),
  },
  data: function () {
    return {
      editColumnsIndex: 0,
      columnList: [],
      editColumnList: [],
      availableColumnList: {},
      csvExportProcessing: false,
      advancedGroupingRefreshing: this.initialAdvancedGroupingRefreshing,
      users: [],
      error: null,
      updating: true,
      page: 1,
      managers: null,
      coaches: null,
      paginationData: null,
      paginationUpdating: true,
      email: '',
      name: this.initName || '',
      search: this.initSearch || '',
      selectedRole: '',
      teamIds: '',
      status: this.statusFromInit(),
      selectedLanguage: '',
      selectedSecondaryLanguage: '',
      expertiseResponse: undefined,
      expertiseIds: '',
      userIds: [],
      coachIds: '',
      batchActionProcessing: false,
      batchActionMessage: null,
      batchActionError: null,
      invitation: {
        from: undefined,
        to: undefined,
      },
      signedUp: {
        from: undefined,
        to: undefined,
      },
      nestedUsers: this.includeNested,
      locationIds: '',
      loginName: null,
      noTeam: false,
      labelIds: [],
      batchActionsAvailable: true,
    };
  },
  computed: {
    languageFilters: function () {
      return this.languages.map((language) => {
        const suffix = language.in_english === language.language ? '' : ` - ${language.language}`;
        return {
          value: language.locale,
          title: `${language.in_english}${suffix}`,
        };
      });
    },

    showActions: function () {
      return (
        !this.hideSearch &&
        (this.csvEndpoint ||
          this.inviteManyPath ||
          this.inviteSinglePath ||
          this.showQuickAddToTeamButton ||
          this.showEditColumns)
      );
    },

    showLanguageColumn: function () {
      return this.languages && this.languages.length > 0;
    },

    columnsCount: function () {
      let currentCount = 8;
      if (this.batchActionsAvailable) {
        currentCount += 1;
      }
      if (this.showLanguageColumn) {
        currentCount += 1;
      }
      return currentCount;
    },

    showingTeam: function () {
      return !!this.teamId;
    },

    params: function () {
      var prms = {};
      prms.page = this.page;
      prms.team_id = this.teamId;
      prms.advanced_grouping_id = this.advancedGroupingId;
      prms.coached = this.coached ? '1' : '0';
      prms.managed = this.managed ? '1' : '0';
      prms.sort = this.sortKey;
      prms.managers = this.managers;
      prms.coaches = this.coaches;
      prms.email = this.email;
      prms.name = this.name;
      prms.search = this.search;
      prms.role = this.selectedRole;
      prms.team_ids = this.teamIds;
      prms.status = this.status;
      prms.primary_language = this.selectedLanguage;
      prms.secondary_language = this.selectedSecondaryLanguage;
      prms.expertise_ids = this.expertiseIds;
      prms.coach_ids = this.coachIds;
      prms.invitation_from = this.invitation.from;
      prms.invitation_to = this.invitation.to;
      prms.signedup_from = this.signedUp.from;
      prms.signedup_to = this.signedUp.to;
      prms.location_ids = this.locationIds;
      prms.login_name = this.loginName;
      prms.label_ids = this.labelIds;
      prms.direction = this.sortOrder === 1 ? 'asc' : 'desc';
      prms.nested_users = this.nestedUsers;
      if (this.noTeam) {
        prms.no_team = true;
      }
      return prms;
    },

    currentParams: function () {
      var params = this.params;
      if (!this.allSelectedAllPages) {
        params.user_ids = this.userIds.join(',');
      }
      return params;
    },

    selectedCount: function () {
      if (this.allSelectedAllPages && this.paginationData) {
        return this.paginationData.total_count;
      } else {
        return this.userIds.length;
      }
    },

    showSearch: function () {
      return (
        !this.hideSearch &&
        this.appliedFilters.filter(function (el) {
          return el !== 'Status';
        }).length === 0
      );
    },
  },

  watch: {
    email: function (newVal) {
      if (newVal.length > 0) {
        this.handleTypedChanges();
      }
    },

    name: function (newVal) {
      if (newVal.length > 0) {
        this.handleTypedChanges();
      }
    },

    loginName: function (newVal) {
      if (newVal.length > 0) {
        this.handleTypedChanges();
      }
    },

    selectedRole: function (newVal) {
      if (newVal.length > 0) {
        this.reloadData();
      }
    },

    status: function () {
      this.reloadData();
      window.emitter.emit('load-batch-actions');
    },

    selectedLanguage: function (newVal) {
      if (newVal && newVal.length > 0) {
        this.reloadData();
      }
    },

    selectedSecondaryLanguage: function (newVal) {
      if (newVal && newVal.length > 0) {
        this.reloadData();
      }
    },

    nestedUsers: function () {
      this.reloadData();
    },

    invitation: function () {
      this.reloadData();
    },

    signedUp: function () {
      this.reloadData();
    },

    search: _.debounce(function () {
      this.reloadData();
    }, 500),
  },

  mounted: function () {
    this.fetchColumns();
    this.$http.get(this.attributeInfoUrl).then((response) => {
      const attributes = response.body.attributes.slice(0);
      let selectOptions = {};
      attributes.forEach((attribute) => {
        selectOptions[attribute.name] = {
          custom_field_id: attribute.custom_field_id,
          name: attribute.name,
          referenced_attribute: attribute.attribute,
          referenced_model: attribute.model,
        };
      });
      this.availableColumnList = selectOptions;
    });
  },

  created: function () {
    this.sortKey = 'users.last_name';
    if (this.name.length > 0) {
      this.addFilter('Name');
    } else if (this.filters.indexOf('Status') > -1 && this.addStatusFilterOnLoad) {
      this.addFilter('Status');
    }
    this.getUsers();
    window.emitter.on('refresh-users', this.reloadData);
  },

  methods: {
    getManagerTranslation: function () {
      const translation = this.t('activerecord.attributes.team.manager');
      if (typeof translation === 'string') {
        return translation;
      }

      return translation.one;
    },

    testId: function (user) {
      return (
        user?.full_name_and_job_title?.full_name &&
        user.full_name_and_job_title.full_name.replace(' ', '')
      );
    },

    getActionMenuItems: function () {
      const menuItems = [];

      if (this.canManage && this.inviteSinglePath) {
        menuItems.push({
          label: this.t('vue_templates.users_list.invite_single'),
          value: this.inviteSinglePath,
          icon: 'add-circle',
        });
      }

      if (this.canManage && this.inviteManyPath) {
        menuItems.push({
          label: this.t('vue_templates.users_list.invite_many'),
          value: this.inviteManyPath,
          icon: 'add-circle',
        });
      }

      if (this.showQuickAddToTeamButton) {
        menuItems.push({
          label: this.t('vue_templates.users_list.add_people_to_team'),
          value: this.quickAddToTeam,
          icon: 'add-circle',
        });
      }

      if (this.csvEndpoint) {
        if (this.csvExportProcessing) {
          menuItems.push({
            label: this.t('vue_templates.activity_feed.downloading_csv'),
            icon: 't-hourglass',
            value: undefined,
          });
        } else {
          menuItems.push({
            label: this.t('vue_templates.activity_feed.export_csv'),
            value: this.exportCsv,
            icon: 'download',
          });
        }
      }

      if (this.canManage && this.showEditColumns) {
        menuItems.push({
          label: this.t('vue_templates.user_batch_actions.edit_columns'),
          value: this.showColumnEditor,
          icon: 't-edit-pencil',
        });
      }

      return menuItems;
    },

    getFilterMenuItems: function () {
      const menuItems = [];

      this.availableFilters.forEach((filter) => {
        if (filter === 'Role') {
          menuItems.push({ label: this.t('activerecord.attributes.user.role'), value: filter });
          return;
        }

        if (filter === 'LoginName') {
          menuItems.push({ label: this.loginNameFilterLabel, value: filter });
          return;
        }

        if (filter === 'NoTeam') {
          menuItems.push({ label: this.t('vue_templates.users_list.no_team'), value: filter });
          return;
        }

        menuItems.push({ label: filter, value: filter });
      });

      return menuItems;
    },

    handleActionMenu: function (selected) {
      if (typeof selected === 'function') {
        selected();
        return;
      }

      if (typeof selected === 'string') {
        window.location.href = selected;
      }
    },

    statusFromInit: function () {
      var statusWhitelist = [
        'not_invited',
        'invite_scheduled',
        'invite_pending',
        'confirmed',
        'deactivated',
      ];
      if (statusWhitelist.includes(this.initStatus)) {
        return [this.initStatus];
      } else {
        return _.dropRight(statusWhitelist);
      }
    },

    toggleAllSelected: function () {
      this.userIds = this.allSelected
        ? []
        : this.users.map(function (u) {
            return u.id;
          });
      this.allSelected = !this.allSelected;
    },

    // TODO - potentially huge refactor: Vue original data can be loaded with
    //     this.$options.data.apply(this)
    // This would eliminate most of the switch if key name could be associated
    // with filter name somehow. Also some filters have multiple attributes.
    // Potentially restructure data.
    removeFilter: function (filter, reload = true) {
      var index = this.appliedFilters.indexOf(filter);
      if (index === -1) {
        return;
      }
      switch (filter) {
        case 'Managers':
          this.managers = null;
          break;
        case 'Coaches':
          this.coaches = null;
          break;
        case 'Coached by':
          this.coachIds = '';
          break;
        case 'Email':
          this.email = '';
          break;
        case 'Name':
          this.name = '';
          break;
        case 'Role':
          this.selectedRole = '';
          break;
        case 'Labels':
          this.labelIds = '';
          break;
        case 'Status':
          this.status = [];
          break;
        case 'Location':
          this.locationIds = '';
          break;
        case 'LoginName':
          this.loginName = null;
          break;
        case 'Primary Language':
          this.selectedLanguage = '';
          break;
        case 'Secondary Language':
          this.selectedSecondaryLanguage = '';
          break;
        case 'Teams':
          this.teamIds = '';
          break;
        case 'Expertise':
          this.expertiseIds = '';
          break;
        case 'Signed up via form at':
          this.signedUp.from = undefined;
          this.signedUp.to = undefined;
          break;
        case 'Invitation date':
          this.invitation.from = undefined;
          this.invitation.to = undefined;
          break;
        case 'NoTeam':
          this.noTeam = false;
      }

      this.appliedFilters.splice(index, 1);
      if (reload) {
        this.reloadData();
      }
    },

    addFilter: function (filter, reload = true) {
      this.appliedFilters.push(filter);
      if (filter !== 'Status') {
        this.search = '';
      }

      switch (filter) {
        case 'Managers':
          this.managers = true;
          if (reload) {
            this.reloadData();
          }
          break;
        case 'Coaches':
          this.coaches = true;
          if (reload) {
            this.reloadData();
          }
          break;
        case 'LoginName':
          break;
        case 'Teams':
          this.removeFilter('NoTeam');
          break;
        case 'NoTeam':
          this.removeFilter('Teams', false);
          this.noTeam = true;
          this.reloadData();
          break;
        default:
      }
    },

    reloadData: function () {
      this.updating = true;
      this.users = [];
      this.page = 1;
      this.paginationData = null;
      this.advancedGroupingRefreshing = false;
      this.getUsers();
    },

    getUsers: function () {
      this.$http
        .get(this.endpoint, { params: this.params })
        .then((response) => {
          this.users = response.data.users;
          this.userIds = _.intersection(
            this.userIds,
            this.users.map((u) => {
              return u.id;
            })
          );
          this.paginationData = response.data.pagination;
          this.error = null;
          this.allSelected =
            this.allSelectedAllPages =
            this.updating =
            this.paginationUpdating =
              false;
          this.refreshTooltips();
        })
        .catch(() => {
          this.error = 'Unable to load users at this time...';
          this.updating = this.paginationUpdating = false;
        });
    },

    handlePageChanged: function (newPage) {
      this.paginationUpdating = true;
      this.page = newPage;
      this.getUsers();
    },

    // Teams selectize
    onChangeTeams: function (value) {
      this.teamIds = value;
      this.reloadData();
    },

    onLoadTeams: function () {
      if (this.teamIds.length > 0) {
        this.teamIds.split(',').forEach(function (teamId) {
          this.addItem(teamId, true);
        });
      }
    },

    ajaxDataTeams: function (query) {
      return {
        search: query,
        page: 1,
      };
    },

    // Labels selectize
    onChangeLabels: function (value) {
      this.labelIds = value;
      this.reloadData();
    },

    ajaxDataLabels: function (query) {
      return {
        search: query,
        page: 1,
      };
    },

    // Expertise selectize
    onInitializeExpertise: function () {
      if (typeof this.expertiseResponse === 'undefined') {
        $.ajax({
          url: '/expertises.json',
          type: 'GET',
          dataType: 'json',
          data: {
            ids: this.expertiseIds,
          },
          success: (res) => {
            this.expertiseResponse = res;
          },
        });
      }
    },

    onLoadExpertise: function () {
      this.expertiseResponse.forEach(function (expertise) {
        const elem = document.getElementById('expertises');
        elem.selectize.addOption(expertise);
      });
    },

    onChangeExpertise: function (value) {
      this.expertiseIds = value;
      this.reloadData();
    },

    ajaxDataExpertise: function (query) {
      return {
        q: query,
        page: 1,
      };
    },

    // Locations selectize
    onChangeLocations: function (value) {
      this.locationIds = value;
      this.reloadData();
    },

    // Coaches selectize
    onChangeCoachedBy: function (value) {
      this.coachIds = value;
      this.reloadData();
    },

    loadCoachedBy: function (query, callback) {
      this.$http.get(this.endpoint, { params: { q: query, coaches: true } }).then(
        (response) => {
          // Map the "new" users endpoint to match the old for selectize users dropdown
          const users = response.body.users.map((user) => {
            return {
              avatar: user.full_name_and_job_title?.avatar,
              name: user.full_name_and_job_title?.full_name,
              on_break: user.status?.on_break,
              manager: user.primary_team_and_manager?.manager,
              role_label: user.role?.role_label,
              role_icon: user.role?.role_icon,
              job_title: user.full_name_and_job_title?.job_title,
              id: user.id,
            };
          });
          callback(users);
        },
        () => {
          callback();
        }
      );
    },

    handleBatchActionMessage: function (message) {
      this.batchActionMessage = message;
      this.getUsers();
      this.batchActionProcessing = false;
      this.batchActionError = null;

      setTimeout(() => {
        this.batchActionMessage = null;
      }, 4000);
    },

    handleBatchActionProcessing: function () {
      this.batchActionProcessing = true;
    },

    handleBatchActionError: function (message) {
      this.batchActionError = message;
      this.batchActionProcessing = false;
      this.batchActionMessage = null;

      setTimeout(function () {
        self.batchActionError = null;
      }, 5000);
    },

    handleRefreshUserTeams: function (teamData, user) {
      user.primary_team = teamData.primary_team;
      user.secondary_teams = teamData.secondary_teams;
    },

    handleRemoveUser: function (user) {
      this.users.splice(this.users.indexOf(user), 1);
    },

    quickAddToTeam: function () {
      window.emitter.emit('show-quick-add-to-team');
    },

    hideBatchActions: function () {
      this.batchActionsAvailable = false;
    },
  },
};
</script>

<style scoped lang="scss">
.users-list_actions-and-filters {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 14px;

  .users-list-filter--align-left {
    margin-left: auto;
  }
}

.users-list__search-input {
  display: inline-block;
  margin-bottom: 24px;
  max-width: 444px;
}

.user-list__user-dropdown {
  margin-top: 4px;
}

.users-list__status-filters input {
  margin-top: 0;
}

.users-list__status-filters label {
  margin-bottom: 0;
}

.users-list__status-filters span,
input,
label {
  vertical-align: middle;
}
</style>
