<template>
  <a :href="topic.show_page" class="tile topic-tile tile--skill" :style="gradientStyles">
    <h5 class="topic-name">{{ truncate(topic.name, 50) }}</h5>
    <i class="ic ic-t-seo-marketing-ideas-strategy-playbook"></i>
  </a>
</template>

<script lang="ts">
import filterTruncate from '@/mixins/filterTruncate';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'TopicTile',
  mixins: [filterTruncate],
  props: {
    topic: {
      type: Object as PropType<ITopic>,
      required: true,
    },
  },
  computed: {
    gradientStyles: function () {
      if (!this.topic?.start_color || !this.topic?.end_color) {
        return {};
      }
      return {
        backgroundImage: `linear-gradient(96.69deg, ${this.topic.start_color} 28.75%, ${this.topic.end_color} 128.68%)`,
      };
    },
  },
});

export interface ITopic {
  id: number;
  name: string;
  show_page: string;
  start_color: string;
  end_color: string;
}
</script>

<style scoped lang="scss">
.tile {
  color: white;
  display: flex;
  height: 104px;
  margin: 0;
  min-width: 150px;
  padding: 0.7em;
  width: 200px;
  transition: box-shadow ease 0.3s;

  &:hover {
    transform: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 20px 40px rgba(0, 0, 0, 0.15);
    border: 0;
  }

  .ic {
    bottom: 0;
    color: $ghost-white;
    float: right;
    font-size: 250%;
    margin-left: auto;
    margin-top: auto;
    opacity: 0.7;
  }

  .topic-name {
    flex: 50%;
    font-size: 20px;
    margin: 5px;
    max-width: 190px;
    word-break: break-word;
  }
}
</style>
