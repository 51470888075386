<script lang="ts">
export default {
  name: 'PinnableLink',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { computed, defineProps, defineEmits, ref } from 'vue';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { useNotification } from '@kyvg/vue3-notification';
import Pin from '@/types/Pins';

const { apiClient } = useApiClient();
const { notify } = useNotification();
const { t } = useI18n();

const emit = defineEmits(['pin-toggled']);
const props = defineProps<{
  pin: Pin;
}>();

const loading = ref(false);
const iconStyle = computed(() => {
  if (loading.value) return 'fas tw-gray-500';
  if (props.pin.pinned) return 'fas';

  return 'far';
});

const togglePin = async () => {
  loading.value = true;
  try {
    if (props.pin.pinned) {
      await removePin();
    } else {
      await addPin();
    }
    emit('pin-toggled', { key: props.pin.key, pinned: !props.pin.pinned });
  } catch (error) {
    notifyPinError(error);
  } finally {
    loading.value = false;
  }
};

const notifyPinError = (error) => {
  notify({
    type: 'error',
    title: error,
  });
};

const addPin = async () => {
  try {
    await apiClient.post('/pins', {
      group: props.pin.group,
      key: props.pin.key,
    });
  } catch (error) {
    throw new Error(t('vue_templates.components.pinnable_link.errors.add_pin'));
  }
};

const removePin = async () => {
  try {
    await apiClient.delete(`/pins/${props.pin.group}/${props.pin.key}`);
  } catch (error) {
    throw new Error(t('vue_templates.components.pinnable_link.errors.remove_pin'));
  }
};

const { generateTestId } = useTestHelpers();
</script>

<template>
  <a
    href="#"
    :aria-label="props.pin.key"
    :data-pinnable="props.pin.pinned"
    :data-test-id="generateTestId('pinnable', props.pin.key)"
    class="px-4 tw-text-inherit"
    @click.prevent="togglePin"
  >
    <FontAwesomeIcon :icon="[iconStyle, 'fa-thumbtack']" size="sm" class="tw-rotate-[30deg]" />
  </a>
</template>
