<template>
  <div class="checkbox-field">
    <div class="checkbox-field__inner">
      <!-- Hidden input required to POST falsy value -->
      <input type="hidden" value="0" :name="name" />
      <input
        v-bind="inputAttrs"
        :id="id"
        :checked="modelValue"
        :disabled="disabled"
        :name="name"
        :required="required"
        :value="Number(modelValue)"
        class="checkbox-field__input"
        type="checkbox"
        @change="$emit('update:modelValue', !modelValue)"
      />
      <InputLabel
        v-if="label || $slots.label"
        :for="id"
        :value="label"
        :required="required"
        :error="error"
        :disabled="disabled"
        class="checkbox-field__label"
      >
        <slot name="label" />
      </InputLabel>
    </div>
    <InputHint v-if="hintText" :error="error" :value="hintText" class="checkbox-field__hint" />
  </div>
</template>

<script>
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import InputLabel from '@/components/atoms/forms/InputLabel/Index.vue';

export default {
  name: 'CheckboxField',
  components: { InputLabel, InputHint },
  compatConfig: {
    MODE: 3,
  },
  props: {
    disabled: Boolean,
    error: Boolean,
    errorMessage: String,
    id: String,
    hint: String,
    label: String,
    placeholder: String,
    required: Boolean,
    inputValue: [String, Number, Boolean],
    modelValue: Boolean,
    name: {
      type: String,
      required: true,
    },
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hintText() {
      return this.error && this.errorMessage ? this.errorMessage : this.hint;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-field__inner {
  position: relative;
  margin-left: 20px;
}

input.checkbox-field__input {
  position: absolute;
  left: -20px;
  margin: 4px 0 0;
  cursor: pointer;
}

label.checkbox-field__label {
  margin-bottom: 0;
  font-weight: 400;

  &[for] {
    cursor: pointer;
  }
}

.checkbox-field__hint {
  margin-top: 5px;
}
</style>
