<template>
  <div class="skill-category-form">
    <TextField
      v-if="nameInputProps && isGlobal"
      :label="nameInputProps.label"
      :model-value="nameInputProps.value"
      :name="`${nameInputProps.modelName}[${nameInputProps.fieldName}]`"
      required
      disabled
      :root-attrs="{
        class: 'm-b-2',
      }"
    />
    <!-- Globally registered pipeline component -->
    <input-uniq-name
      v-else-if="nameInputProps"
      :id="nameInputProps.id || undefined"
      :search-endpoint="nameInputProps.searchEndpoint"
      :value="nameInputProps.value"
      :label="nameInputProps.label"
      :model-name="nameInputProps.modelName"
      :error="nameInputProps.error"
      :placeholder="t('simple_form.placeholders.carousel.title')"
      data-asset-pipeline
      field-name="name"
      required
      @update="handlePropertyUpdate('previewTitle', $event)"
    />

    <slot />

    <template v-if="!isGlobal && enableColours">
      <CheckboxField
        id="skill_categories_custom_colors"
        v-model="customColours"
        :label="t('activerecord.attributes.skill_category.custom_colors')"
        name="skill_category[custom_colors]"
      />
      <SlideTransition>
        <div v-if="customColours">
          <ColourPicker
            v-if="initColourStart || defaultColourStart"
            field-name="skill_category[start_color]"
            :init-color="initColourStart || defaultColourStart"
            :default-color="defaultColourStart"
            data-test-id="skill-category-form-colour-start"
            @update="handlePropertyUpdate('previewColourStart', $event)"
          />
          <ColourPicker
            v-if="initColourEnd || defaultColourEnd"
            field-name="skill_category[end_color]"
            :init-color="initColourEnd || defaultColourEnd"
            :default-color="defaultColourEnd"
            data-test-id="skill-category-form-colour-end"
            @update="handlePropertyUpdate('previewColourEnd', $event)"
          />
        </div>
      </SlideTransition>
      <span class="tw-block tw-mt-6 tw-font-semibold">
        {{ t('skill_categories.modal.preview') }}
      </span>
      <TopicTile
        :topic="{
          name: previewTitle,
          start_color: previewColourStart,
          end_color: previewColourEnd,
        }"
        class="m-t-2 m-b-4 m-l-0"
      />
    </template>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import CheckboxField from '@/components/molecules/forms/CheckboxField/Index.vue';
import ColourPicker from '@/components/molecules/forms/ColorInput/Index.vue';
import SlideTransition from '@/components/atoms/Transitions/SlideX.vue';
import TopicTile from '@/components/molecules/TopicTile/Index.vue';
import TextField from '@/components/molecules/forms/TextField/Index.vue';

export default {
  name: 'SkillCategoryForm',
  components: { CheckboxField, ColourPicker, SlideTransition, TopicTile, TextField },
  mixins: [i18n],
  props: {
    enableColours: Boolean,
    isGlobal: Boolean,
    initCustomColours: Boolean,
    initColourStart: String,
    initColourEnd: String,
    defaultColourStart: String,
    defaultColourEnd: String,
    errors: Object,
    nameInputProps: Object,
  },
  data() {
    return {
      customColours: this.initCustomColours,
      previewColourStart:
        (this.initCustomColours && this.initColourStart) || this.defaultColourStart,
      previewColourEnd: (this.initCustomColours && this.initColourEnd) || this.defaultColourEnd,
      previewTitle: this.nameInputProps?.value,
    };
  },
  watch: {
    customColours(value) {
      if (value) {
        this.previewColourStart = this.initColourStart || this.defaultColourStart;
        this.previewColourEnd = this.initColourEnd || this.defaultColourEnd;
      } else {
        this.previewColourStart = this.defaultColourStart;
        this.previewColourEnd = this.defaultColourEnd;
      }
    },
  },
  methods: {
    handlePropertyUpdate(model, value) {
      this[model] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.skill-category-form {
  color: inherit;
}
</style>
