<script lang="ts">
export default {
  name: 'AddUserSkillModal',
  compatConfig: { MODE: 3 },
};
interface Skill {
  id: number;
  name: string;
  description: string;
  added: boolean;
}
</script>

<script setup lang="ts">
import { defineEmits, defineProps, ref, Ref } from 'vue';
import BaseModal from '@/components/atoms/BaseModal/Index.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import SearchInput from '@/components/atoms/SearchInput/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { useApiClient } from '@/composables/useApiClient';

interface Props {
  title: string;
  userId: string;
  searchSkillsEndpoint: string;
  createUserSkillsEndpoint: string;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:model-value']);
const { t } = useI18n();
const { apiClient } = useApiClient();

const open: Ref<boolean> = ref(false);
const loading: Ref<boolean> = ref(false);
const search: Ref<string> = ref('');
const skills: Ref<Skill[]> = ref([]);
const createUserSkillsEndpointWithId = ref(
  `${props.createUserSkillsEndpoint}?user_id=${props.userId}`
);

const addSkill = async (skill: Skill) => {
  const skillIndex = skills.value.findIndex((s) => s.id === skill.id);
  if (skillIndex !== -1) {
    const updatedSkill: Skill = { ...skill, added: true };
    skills.value = skills.value
      .slice(0, skillIndex)
      .concat(updatedSkill)
      .concat(skills.value.slice(skillIndex + 1));
  }

  const params = {
    user_id: props.userId,
    skill_id: skill.id,
  };

  try {
    await apiClient.post(createUserSkillsEndpointWithId.value, params);
    window.emitter.emit('user-skill-updated');
  } catch (error) {
    console.error('Error adding skill:', error);
  }
};

const getSkills = async () => {
  const params = { search: search.value, limit: 15 };
  loading.value = true;
  try {
    const response = await apiClient.get(props.searchSkillsEndpoint, {
      params,
    });
    skills.value = response.data.skills;
  } catch (error) {
    console.error('Error fetching skills:', error);
  } finally {
    loading.value = false;
  }
};
const close = () => {
  open.value = false;
  emit('update:model-value', false);
};
</script>

<template>
  <BaseModal
    v-model:model-value="open"
    class="add-user-skill-modal"
    size="medium"
    :title="title"
    :show-footer="false"
    :focus-trap-enabled="false"
    @update:model-value="close"
  >
    <SearchInput
      v-model="search"
      full-width
      :placeholder="t('helpers.buttons.search')"
      data-test-id="add-user-skill-modal-search-input"
      @update:modelValue="getSkills"
    />
    <template v-if="loading">
      <div class="loader"></div>
    </template>
    <template v-else-if="search && search.length > 0">
      <div class="tw-my-4">
        <strong>{{ t('vue_templates.skills.skills_add_skills_modal.results_for') }}</strong>
        <em>{{ search }}</em>
      </div>
      <table class="tw-table-auto tw-w-full" data-test-id="add-user-skill-modal-skill-table">
        <caption class="tw-sr-only">
          {{
            t('vue_templates.skills.skills_add_skills_modal.table_caption')
          }}
        </caption>
        <colgroup>
          <col />
          <col class="tw-w-32" />
        </colgroup>
        <tbody>
          <template v-if="skills.length === 0">
            <tr>
              <td class="tw-my-4">
                {{ t('vue_templates.skills.skills_add_skills_modal.no_skills_found') }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="skill in skills" :key="skill.id" class="hover:tw-bg-gray-100">
              <td class="tw-p-1">
                <span
                  v-tippy="{ content: skill.description }"
                  data-test-id="add-user-skill-modal-skill-name"
                  v-html="skill.name"
                ></span>
              </td>
              <td v-if="skill.added" class="tw-text-green-700 tw-p-1">
                <div
                  class="tw-inline-block tw-p-4"
                  :data-test-id="`add-user-skill-modal-added-message-${skill.id}`"
                >
                  <FontAwesomeIcon icon="fa-solid fa-check" size="lg" />
                  <span>{{ t('vue_templates.quick_add.added') }}</span>
                </div>
              </td>
              <td v-else class="tw-p-1">
                <BaseButton
                  variant="text"
                  fa-prepend-icon="fa-solid fa-plus"
                  fa-icon-size="lg"
                  theme="brand"
                  :data-test-id="`add-user-skill-modal-add-skill-button-${skill.id}`"
                  @click.prevent="addSkill(skill)"
                  @keyup.prevent.enter="addSkill(skill)"
                  >{{ t('helpers.buttons.add') }}
                </BaseButton>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <BaseButton
        theme="brand"
        data-test-id="add-user-skill-modal-finalise-button"
        class="tw-float-right tw-mt-12"
        @click.prevent="close"
        @keyup.prevent.enter="close"
      >
        {{ t('vue_templates.skills.skills_add_skills_modal.finalise') }}</BaseButton
      >
    </template>
    <template v-else>
      <div class="tw-my-4">
        {{ t('vue_templates.skills.skills_add_skills_modal.search_for_skills') }}
      </div>
    </template>
  </BaseModal>
</template>
