<script lang="ts">
export default {
  name: 'LinkTile',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import PinnableLink from '@/components/molecules/PinnableLink/Index.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ILinkTile } from '@/types/ILinkTile';
import { useTestHelpers } from '@/composables/useTestHelpers';

const { generateTestId } = useTestHelpers();

const emit = defineEmits(['pin-toggled']);

const props = defineProps<{
  tile: ILinkTile;
  loading?: boolean;
  enablePins?: boolean;
}>();
</script>

<template>
  <a
    :href="tile.route"
    class="tw-flex tw-shadow tw-border-solid tw-border-thin tw-border-gray-500 tw-rounded-xl tw-bg-white tw-underline-none tw-text-inherit tw-p-7 tw-w-full"
    :data-test-id="generateTestId('central report tile', tile.key)"
  >
    <template v-if="loading">
      <div class="skeleton-loading small central-report-tile__tile-label--loading"></div>
    </template>
    <template v-else>
      <div class="tw-flex tw-flex-1">
        <div class="tw-flex tw-flex-1" :class="tile.theme === 'default' && 'tw-flex-col'">
          <!-- TODO: At some point the BaseIcon needs to be refactored -->
          <component
            :is="tile.icon.startsWith('fa-') ? FontAwesomeIcon : BaseIcon"
            :icon="tile.icon.startsWith('fa-') ? `${tile.icon} fa-regular` : undefined"
            :icon-name="!tile.icon.startsWith('fa-') ? tile.icon : undefined"
            size="lg"
            class="fa-fw !tw-text-[24px] !tw-h-[28px] tw-w-[44px] tw-p-4 tw-mr-5 tw-mb-5 tw-rounded-xl"
            :style="{ color: tile.icon_color, backgroundColor: tile.icon_background_color }"
          />
          <div class="tw-flex-col tw-flex-1" :class="{ 'tw-pl-1': tile.theme === 'default' }">
            <span class="tw-block tw-font-medium tw-text-2xl tw-mb-2">
              {{ tile.title }}
            </span>
            <span class="tw-block">
              {{ tile.description }}
            </span>
            <slot name="additional-content" />
          </div>
        </div>
        <PinnableLink
          v-if="tile.group && props.enablePins"
          :pin="{
            key: tile.key,
            group: tile.group as 'my_team' | 'central_report',
            pinned: tile.pinned || false,
          }"
          @pin-toggled="
            (params) => {
              emit('pin-toggled', params);
            }
          "
        />
      </div>
    </template>
  </a>
</template>
